<template>
    <div class="wrapper">
        <van-sticky>
            <div class="search-t">
                <van-search
                    v-model="keywords"
                    show-action
                    shape="round"
                    placeholder="搜索商品"
                    @search="onSearch"
                    @cancel="onCancel"
                />
            </div>
            <van-tabs v-model="categoryId">
                <van-tab
                    v-for="(item, index) in categoryList"
                    :key="index"
                    :name="item.id"
                >
                    <template #title>
                        <div class="tab-item flex ai-c fd-c">
                            <img :src="item.icon" alt="" />
                            <div class="name">{{ item.name }}</div>
                        </div>
                    </template>
                </van-tab>
            </van-tabs>
            <div class="nav flex">
              <div class="item flex ai-c jc-c " :class="orderActive==item.orders[0]||orderActive==item.orders[1]?'active':''" @click="navClick(item,index)" v-for="(item, index) in nav" :key="index">
                {{item.name}}
                <div class="sort flex fd-c" v-if="item.orders.length>1">
                  <template v-for="(im, ix) in item.orders">
                    <van-icon name="play"  size="12" :class="orderActive==im&&'active'" :key="ix" />
                  </template>
                </div>
              </div>
            </div>
        </van-sticky>
         <div class="loveGoodsList">
          <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
          >
              <div class="goodsList flex">
                <div class="list flex fd-c">
                    <template v-for="(item, index) in goodsList">
                      <v-loveGoods v-if="index%2==0" :goods="item" :key="index" />
                    </template>
                </div>
                <div class="list flex fd-c ai-fd">
                    <template v-for="(item, index) in goodsList">
                        <v-loveGoods v-if="index%2!=0" :goods="item" :key="index" />
                    </template>
                </div>
              </div>
          </van-list>
        </div>
    </div>
</template>

<script>
import loveGoods from '@/components/loveGoods'
import { goodsPage, category } from "../../../api";
export default {
    name: 'productList',
    components: {
        "v-loveGoods": loveGoods,
    },
    props: {},
    data() {
        return {
            keywords: "",
            categoryId: "",
            categoryList: [],
            goodsList: [],
            nav: [{
              name: '综合',
              orders: [0]
            },{
              name: '销量',
              orders: [2,1]
            },{
              name: '价格',
              orders: [4,3]
            }],
            orderActive: 0,
            current: 1,
            loading: false,
            finished: true,
            timer: null
        };
    },
    watch: {
        categoryId(val) {
            this.current = 1
            this.finished = true;
            this.goodsList = []
            this.goodsPage()
        },
        keywords() {
          clearTimeout(this.timer)
          this.timer = setTimeout(()=>{
            this.finished = true;
            this.current = 1
            this.goodsList = []
            this.goodsPage()
          },500)
        }
    },
    computed: {},
    methods: {
        onSearch(val) {},
        onCancel() {
            this.$router.go(-1);
        },
        navClick(item,index) {
          var orderActive = item.orders[0]
          for (var i in item.orders) {
            var im = item.orders[item.orders.length-i-1]
            if (im!=this.orderActive) {
              orderActive = im
            }
          }
          this.orderActive = orderActive
          this.current = 1
          this.goodsList = []
          this.goodsPage()
        },
        goodsPage() {
            this.$toast.loading({
              duration: 0,
              forbidClick: true
            })
            goodsPage({
                current: this.current,
                size: 20,
                goodsCategoryId: this.categoryId,
                keywords:this.keywords,
                order: this.orderActive
            }).then((res) => {
              var list = res.data.data.records
              // 加载状态结束
              this.loading = false;
              this.current++
              if (list.length==0) {
                this.finished = true;
              } else {
                this.finished = false;
              }
              for (var item of list) {
                item.pic = item.pic?item.pic.split(',')[0]:[]
                item.goodsId = item.id
                this.goodsList.push(item)
              }
            }).finally(()=>{
              this.$toast.clear()
            });
        },
        //商品类别
        category() {
            category({}).then((res) => {
                this.categoryList = res.data.data;
            });
        },
        onLoad() {
          // 异步更新数据
          // setTimeout 仅做示例，真实场景中一般为 ajax 请求
          this.goodsPage()
        },
    },
    activated() {
        var productListRefresh = localStorage.getItem('productListRefresh')
        if (!productListRefresh) {
          localStorage.setItem('productListRefresh', 1)
          this.categoryId = Number(this.$route.query.id)
        }
    },
    created() {
        if (this.$route.query.id) {
            localStorage.setItem('productListRefresh', 1)
            this.categoryId = Number(this.$route.query.id);
        }
        
        this.category();
    },
    mounted() {},
};
</script>
<style scoped>
.search-t {
    width: 100%;
    z-index: 100;
    border-bottom: 0.02rem solid rgba(244, 244, 244, 1);
}
.search-t >>> .van-search__action {
    color: rgba(0, 0, 0, 0);
    background-image: url(/img/icon-home.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.36rem 0.36rem;
}
.wrapper >>> .van-tabs--line .van-tabs__wrap {
    height: auto;
}
.van-tab--active .tab-item img {
    border-color: #fe5625;
}
.van-tab--active .tab-item .name {
    background: #fe5625;
    color: #ffffff;
}
.tab-item img {
    width: 0.9rem;
    height: 0.9rem;
    border: 0.02rem solid #fff;
    background: linear-gradient(0deg, #bbe8a4 0%, #ebfde3 100%);
    border-radius: 0.3rem;
    margin-top: 0.1rem;
}
.tab-item .name {
    line-height: 0.32rem;
    background: #fff;
    border-radius: 0.16rem;
    font-size: 0.22rem;
    font-family: PingFang;
    font-weight: 500;
    color: #282828;
    padding: 0 0.08rem;
    margin-top: 0.1rem;
}
.wrapper >>> .van-tabs__line {
    display: none;
}
.wrapper >>> .van-tabs__wrap--scrollable .van-tab {
    padding: 0 6px;
}
.goodsList {
  width: 7.1rem;
  margin-left: 0.2rem;
}
.goodsList .list{
    flex: 1;
    margin-top: .2rem;
}
.nav{
  width: 100%;
  height: .94rem;
  background-color: #fff;
  border-top: 1px solid #F4F4F4;
}
.nav .item {
  flex: 1;
  height: 100%;
  font-size: .30rem;
  font-family: PingFang;
  font-weight: 500;
  color: #4A4A4A;
}
.nav .item .sort{
  margin-left: .1rem;
}
.nav .item .sort>>>.van-icon-play:nth-child(1){
  transform:rotate(-90deg);
  margin-bottom: -.05rem;
  color: #A0A0A0;
}
.nav .item .sort>>>.van-icon-play:nth-child(2){
  transform:rotate(90deg);
  margin-top: -.05rem;
  color: #A0A0A0;
}
.nav .active {
  color: #E34611!important;
}
</style>
